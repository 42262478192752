import axios from 'axios';
import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ModalContext } from "../layout";
import { EmployerForm } from "../forms";
import greenBackgroundPng from "../../assets/images/background-green.png";
import Cross from "../../assets/svg/cross";

const ReferModal: React.FC = () => {
  const { setModal } = useContext(ModalContext);
  const [captchaToken, setCaptchaToken] = useState('')
  const [showCaptchaNudge, setShowCaptchaNudge] = useState(false)
  const [loading, setLoading] = useState(false);

  const onSubmit = (values: {[key: string]: string}) => {
    const submit = async () => {
      if (captchaToken) {
        setLoading(true)
        let data = {
          ...(values.name ? {full_name: values.name} : {}),
          company_name: values.company,
          company_size: parseInt(values.size),
          hr_full_name: values.hrName,
          hr_email: values.hrEmail,
          ...(values.hrPhone ? {hr_phone_number: values.hrPhone} : {}),
          hear_about_us: values.hearAboutUs,
          context: "Refer to employer",
          captcha_token: captchaToken,
        }
      await axios.post(
          `${process.env.GATSBY_API_URL}/v1/api/contacts/hr/`,
          data
        ).then(() => {
          setModal(null)
        }).catch(e => {
          console.log(e)
          return
        }).finally(() => setLoading(false))
      } else {
        setShowCaptchaNudge(true)
      }
    }

    submit()
  }

  const onCaptchaSuccess = (captchaToken: string) => {
    setCaptchaToken(captchaToken)
    setShowCaptchaNudge(false)
  }

  return <>
    <div className="bg-cover bg-center rounded-xl p-16 relative" style={{backgroundImage: `url(${greenBackgroundPng})` }}>
      <div className="flex flex-col justify-center items-center">
        <div className="absolute top-0 w-full flex justify-end">
          <button className="m-8" onClick={() => setModal(null)}><Cross/></button>
        </div>
        <h3 className="text-h3 font-dm text-white mb-0">Refer to employer</h3>
        <div className="text-b1 font-nun text-white max-w-xl mb-8">
          Refer us to your HR team and we'll reach out to them on your behalf. Prefer to remain anonymous? Not a problem - just leave out your name.
        </div>
        <EmployerForm buttonText="Refer to employer" onSubmit={onSubmit} buttonColour='bg-black' loading={loading} />
        <div className="mt-8">
          <div className={`${showCaptchaNudge ? "" : "hidden"} text-sm font-nun text-red-400`}>Please complete captcha</div>
          <ReCAPTCHA
            className="rounded-md"
            sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
            onChange={onCaptchaSuccess}
          />
        </div>
      </div>
    </div>
  </>
}

export default ReferModal;
