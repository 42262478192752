import React from "react";

const EnterpriseIcon: React.FC = () => <>
  <svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.664062" width="82" height="82" rx="8" fill="url(#paint0_linear_4370_11039)"/>
  <mask id="mask0_4370_11039" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="13" y="13" width="57" height="57">
  <path d="M13.5 13.6641H69.5V69.6641H13.5V13.6641Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_4370_11039)">
  <path d="M45.3341 67.5859H20.9102V26.9019C20.9102 25.7614 21.6385 24.7483 22.7195 24.385L45.3341 16.7834V67.5859Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M59.0861 62.4825V67.5859H45.3398V40.0223H60.5069C61.512 40.0223 62.3258 40.8361 62.3258 41.8412V56.4319" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3398 67.5859H67.4502C68.1314 67.5859 68.6836 67.0338 68.6836 66.3526V62.4827H45.3398V67.5859Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M63.6454 56.2362H64.1085C66.6309 56.2362 68.6758 58.2811 68.6758 60.8035V62.4844H59.0781V60.8035C59.0781 58.2811 61.123 56.2362 63.6454 56.2362Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.5536 67.5859H20.9119V62.4827H14.3203V66.3526C14.3203 67.0338 14.8724 67.5859 15.5536 67.5859Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.267 56.2362H20.912V62.4844H14.3203V62.1829C14.3203 58.8986 16.9828 56.2362 20.267 56.2362Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M53.833 40.0234H45.3398V16.785L53.0232 24.2119C53.5408 24.7121 53.833 25.4012 53.833 26.1211V36.1566" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.8867 22.9883V17.4484C26.8867 16.5067 27.6502 15.7433 28.593 15.7433H28.7658C29.7075 15.7433 30.4709 16.5067 30.4709 17.4484V21.783" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3361 22.982L26.8867 29.1836" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3361 28.8883L26.8867 35.0898" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3361 34.7945L26.8867 40.9961" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3361 40.6969L26.8867 46.8984" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M29.9324 51.7809L26.8867 52.8047" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M45.3356 46.6039L33.6055 50.5469" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M36.6086 67.5859H26.8867V60.6171C26.8867 60.0768 27.2466 59.6027 27.767 59.4574L35.4021 57.3259C36.0083 57.1566 36.6086 57.6124 36.6086 58.2417V67.5859Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M57.7913 46.793H49.7467C49.4357 46.793 49.1836 46.5409 49.1836 46.2299V44.2554C49.1836 43.9443 49.4357 43.6922 49.7467 43.6922H57.7913C58.1024 43.6922 58.3545 43.9443 58.3545 44.2554V46.2299C58.3545 46.5409 58.1024 46.793 57.7913 46.793Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M57.7913 53.0273H49.7467C49.4357 53.0273 49.1836 52.7752 49.1836 52.4642V50.4896C49.1836 50.1787 49.4357 49.9266 49.7467 49.9266H57.7913C58.1024 49.9266 58.3545 50.1787 58.3545 50.4896V52.4642C58.3545 52.7752 58.1024 53.0273 57.7913 53.0273Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M52.4075 59.3281H49.7467C49.4357 59.3281 49.1836 59.076 49.1836 58.7651V56.7905C49.1836 56.4795 49.4357 56.2273 49.7467 56.2273H52.4075C52.7186 56.2273 52.9707 56.4795 52.9707 56.7905V58.7651C52.9707 59.076 52.7186 59.3281 52.4075 59.3281Z" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31.75 58.346V67.5859" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_4370_11039" x1="41.5" y1="0.664062" x2="41.5" y2="82.6641" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  </defs>
  </svg>
</>

export default EnterpriseIcon;
