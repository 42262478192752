import React, { useContext } from "react";

import { buttonStyle } from "../styles";
import { LimitMaxWidth, ModalContext } from "../layout";
import DemoModal from "../modals/DemoModal";
import ReferModal from "../modals/ReferModal";
import bannerPairPng from "../../assets/images/banner-pair.png";
import bannerPairAvif from "../../assets/images/banner-pair.avif";
import bannerPairWebp from "../../assets/images/banner-pair.webp";

type BannerProps = {
  showEmployerReferral: boolean;
  title: string;
  subtitle: string;
}

const DemoAndEmployerBannerSection: React.FC<BannerProps> = ({ showEmployerReferral, title, subtitle }) => {
  const { setModal } = useContext(ModalContext);

  return <>
    <div className="bg-syr-smoke pt-12">
      <LimitMaxWidth>
        <div className="md:flex items-end justify-between">
          <div className="md:w-1/2 pb-12 flex flex-col justify-center">
            <h3 className="max-sm:text-m-lg-t sm:text-h2 font-dm mb-0">
              {title}
            </h3>
            <p className="max-sm:text-m-t3 sm:text-s2 font-nun">
              {subtitle}
            </p>
            <div className="mt-8 flex space-x-8">
            <button
              className={`
                ${buttonStyle}
                bg-black
                text-white
              `}
              onClick={() => setModal(<DemoModal/>)}
            >
              Book a demo
            </button>
            {showEmployerReferral ? 
              <button
                className={`
                  ${buttonStyle}
                  bg-white
                  border
                  border-solid
                  border-black
                  text-black"
                `}
                onClick={() => setModal(<ReferModal/>)}
              >
                Refer to employer
              </button> :
              <></>
            }
            </div>
          </div>
          <div className="max-md:hidden md:w-5/12">
            <picture className="pt-8 object-scale-down object-bottom">
              <source srcSet={bannerPairAvif} type="image/avif" />
              <source srcSet={bannerPairWebp} type="image/webp" />
              <img src={bannerPairPng} alt="People" />
            </picture>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

export default DemoAndEmployerBannerSection;
