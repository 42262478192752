import React from "react";

const ValidatedIcon: React.FC = () => <>
  <svg width="82" height="83" viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="0.664062" width="82" height="82" rx="8" fill="url(#paint0_linear_3689_29972)"/>
  <mask id="mask0_3689_29972" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="14" y="14" width="54" height="55">
  <path d="M14 14.6641H68V68.6641H14V14.6641Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_3689_29972)">
  <path d="M56.457 15.457C59.9375 15.457 62.7852 18.3047 62.7852 21.7852H29.5624C29.5624 18.3047 26.7148 15.457 23.2344 15.457" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M50.9754 59.9609C51.8508 58.1482 55.2357 51.1339 52.8678 49.3558C51.2592 48.1479 50.125 46.3045 50.125 44.1406C50.125 40.6457 52.9583 37.8126 56.4532 37.8126C59.948 37.8126 62.7814 40.6457 62.7814 44.1406C62.7814 46.3045 61.6471 48.1479 60.0386 49.3558C57.6707 51.1339 61.0556 58.1482 61.931 59.9609H50.9754Z" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M50.9766 59.9609H61.9321C63.6724 59.9609 65.0962 61.3848 65.0962 63.125V64.707H47.8125V63.125C47.8125 61.3848 49.2363 59.9609 50.9766 59.9609Z" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M56.457 37.8126V21.7812M54.084 67.8711H16.9062V21.7812C16.9062 18.3008 19.7539 15.4531 23.2344 15.4531H56.457M57.248 67.8711H55.666M49.3972 67.8711V64.707H63.5169V67.8711H58.8301" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M27.1875 49.5743H39.8438M27.1875 53.5293H39.8438M27.1875 57.4844H39.8438" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M25.6055 36.92V31.3828C25.6055 30.5127 26.3174 29.8008 27.1875 29.8008H39.8438C40.7139 29.8008 41.4258 30.5127 41.4258 31.3828V44.0392C41.4258 44.9093 40.7139 45.6211 39.8438 45.6211H27.1875C26.3174 45.6211 25.6055 44.9093 25.6055 44.0392V38.502" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  <path d="M53.293 28.9042V27.3222M56.4569 44.1445V42.5625M53.293 25.7401V24.1581" stroke="white" stroke-width="1.5" stroke-miterlimit="2.613"/>
  <path d="M36.876 35.4706L32.4014 39.9453L30.1641 37.7079" stroke="white" stroke-width="1.5" stroke-miterlimit="22.926"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_3689_29972" x1="55.5" y1="6.16406" x2="18.5" y2="69.1641" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  </defs>
  </svg>
</>

export default ValidatedIcon;
