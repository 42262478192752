import React from "react";

import { LimitMaxWidth } from "../layout";

type SectionProps = {
  image: string
} & ContentProps

const HeroNoCtaSection: React.FC<SectionProps> = ({ image, ...contentProps}) => <>
  <div className="bg-gradient-to-r from-syr-main to-syr-snd">
    <div className="bg-cover bg-no-repeat bg-right-top" style={{backgroundImage: `url(${image})` }}>
      <div className="bg-cover bg-gradient-to-r from-syr-nero">
        <HeroNoCtaContent {...contentProps}/>
      </div>
    </div>
  </div>
</>

export default HeroNoCtaSection;

type ContentProps = {
  heading: React.ReactNode;
  subheading?: string;
  text?: string;
  bodyNodes?: React.ReactNode[];
}

const HeroNoCtaContent: React.FC<ContentProps> = ({ heading, subheading, text, bodyNodes }) => <>
  <LimitMaxWidth>
    <div className="lg:w-6/12 md:max-lg:w-6/12 sm:max-md:w-10/12 flex flex-col first:pt-24 last:pb-32 py-0">
      <div className="max-sm:text-m-lg-t sm:text-h2 font-dm text-white">{heading}</div>
      {subheading && <div className="max-sm:text-28 sm:text-38 font-dmi text-white">{subheading}</div>}
      {text ? <div className="max-sm:text-m-body sm:text-b1 font-nun text-white">{text}</div> : <></>}
      {bodyNodes && bodyNodes.map(BN => <>{BN}</>)}
    </div>
  </LimitMaxWidth>
</>
