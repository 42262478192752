import React from "react";

const RightArrowIcon: React.FC = () => <>
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="74" height="74" rx="7" fill="black"/>
    <path d="M23 37L50 37" stroke="#FEFEFE" stroke-width="2" stroke-linecap="round"/>
    <path d="M41 27L51 37L41 47" stroke="#FEFEFE" stroke-width="2" stroke-linecap="round"/>
  </svg>
</>

export default RightArrowIcon;
