import React, { useState } from "react";

import { mod } from "../utils"
import { Base, LimitMaxWidth, SectionHeading } from "../components/layout";
import HeroNoCtaSection from "../components/page-sections/HeroNoCtaSection";
import DemoAndEmployerBannerSection from "../components/page-sections/DemoAndEmployerBannerSection";
import CertificationsSection from "../components/page-sections/CertificationsSection";
import SEO from "../components/seo";
import CaretDownWhite from "../assets/svg/caret-down-white";
import SecureIcon from "../assets/svg/icon-privacy-secure";
import ValidatedIcon from "../assets/svg/icon-privacy-validated";
import EnterpriseIcon from "../assets/svg/icon-privacy-enterprise";
import RightArrowIcon from "../assets/svg/right-arrow";
import heroWebp from "../assets/images/hero-security.webp";
import introPng from "../assets/images/intro-security.png";
import introAvif from "../assets/images/intro-security.avif";
import introWebp from "../assets/images/intro-security.avif";

const bottomBanner = <DemoAndEmployerBannerSection title="People are your asset." subtitle="So let's start a conversation." showEmployerReferral={true} />

export const Head = () => <SEO title="Syrona Health | Security" />

const SecurityPage: React.FC = () => <Base bottomBanner={bottomBanner}><SecurityContent /></Base>

export default SecurityPage;

const SecurityContent: React.FC = () => <>
  <HeroNoCtaSection
    heading={<>Data Security: Our Forte</>}
    subheading="for your peace of mind."
    text="Trust drives us forward, and respecting your privacy is key to gaining and keeping that trust."
    image={heroWebp}
  />
  <IntroSection/>
  <UserPrivacySection/>
  <CertificationsSection/>
  <SecureByDesignSection/>
</>

const IntroSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <SectionHeading>
        Data Security is embedded within our foundation
      </SectionHeading>
      <div className="flex max-md:flex-col justify-center items-center max-md:-space-y-20 md:-space-x-16">
        <picture className="max-md:h-4/6 max-sm:w-full sm:max-md:w-11/12 md:w-6/12">
          <source srcSet={introAvif} type="image/avif" />
          <source srcSet={introWebp} type="image/webp" />
          <img src={introPng} alt=""/>
        </picture>
        <div className="z-10 max-sm:w-full sm:max-md:w-10/12 md:w-5/12 md:mt-64 bg-white rounded-lg shadow-sym-2xl flex flex-col space-y-4 p-8">
          <h4 className="max-sm:text-m-t2 sm:text-h4 font-dm text-black">
            We combine data with cutting-edge medical research to raise the standard of healthcare.
          </h4>
          <p className="max-sm:text-m-b sm:text-b1 font-nun text-black">
            By collecting and analysing vast amounts of data, we gain insights into patient health trends and identify potential risk factors, allowing us to develop personalised treatment plans and interventions. This data-driven approach results in improved outcomes for our patients.
          </p>
        </div>
      </div>

    </LimitMaxWidth>
  </div>
</>

const privacyBoxes = [
  {
    icon: SecureIcon,
    title: "Secure",
    text: "All data is stored and accessed within the U.K with strict audited access controls to ensure the protection of our member's data.",
  },
  {
    icon: ValidatedIcon,
    title: "Validated",
    text: "The nation's largest organisations trust us because of our compliance standards across security, availability and integrity.",
  },
  {
    icon: EnterpriseIcon,
    title: "Enterprise Ready",
    text: "Independent annual audits confirm adherence to industry-leading standards for encryption, network management and application security.",
  },
]

const UserPrivacySection: React.FC = () => {
  const privacyOrdering = [
    "order-0",
    "order-1",
    "order-2",
  ]

  const [offsetIdx, setOffsetIdx] = useState(0)

  const getOrder = (origIdx: number) => {
    return privacyOrdering[mod(origIdx+offsetIdx,privacyOrdering.length)]
  }

  const nextPrivacy = () => {
    setOffsetIdx(mod(offsetIdx-1, 3))
  }

  return <>
    <div className="bg-gradient-to-r from-syr-main to-syr-snd py-12">
      <LimitMaxWidth>
        <div className="md:flex items-center">
          <div className="md:w-4/12 max-sm:px-8 max-sm:pb-8">
            <h3 className="max-sm:text-m-lg-t sm:text-h3 font-dm text-white">
              User privacy is one of our core values
            </h3>
            <p className="max-sm:text-m-b sm:text-s1 font-nun text-white">
              With our industry-leading security features and policies, you can rest easy knowing protecting your information is always our priority.
            </p>
          </div>
          <div className="md:w-8/12 max-lg:pl-8 sm:max-md:mt-8 relative">
            <div className="flex justify-start overflow-visible">
              {privacyBoxes.map((t, idx) => {
                return (
                  <div className={`flex-none mx-4 ${getOrder(idx)} max-sm:h-80 max-sm:w-80 sm:h-96 sm:w-96 bg-white rounded-md`}>
                    <div className="h-full flex flex-col justify-center items-center px-8">
                      <t.icon/>
                      <h4 className="mt-4 max-sm:text-m-t2 sm:text-h4 font-dm text-black">{t.title}</h4>
                      <p className="max-sm:text-m-b sm:text-lg font-nun text-center">{t.text}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <button onClick={nextPrivacy} className="absolute md:top-72 top-80 md:-left-4 max-sm:top-60">
              <RightArrowIcon/>
            </button>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const dataSecurityBoxes = [
  {
    title: "Certifications",
    bullets: [
      "ISO 27001 certification",
      // "Cyber Essentials (Plus) certification",
      "CQC Registered",
      "Class I Medical Device - UKCA & CE Mark",
    ],
  },
  {
    title: "Partners",
    text: "Our clinicians and nurses are GMC and NMC registered respectively. Our lab partners are UKAS accredited. Some of our partners hold security accreditations including ISO 27001, SOC 1, 2 & 3, FIPS 140-2 Validated, supporting HIPAA compliance.",
  },
  {
    title: "External Assessments",
    text: "We enlist independent security firms to conduct annual third-party penetration tests to assess our site for vulnerabilities. We have also undergone comprehensive Information Security Due Diligence assessments conducted on us by leading industry experts.",
  },
]
const SecureByDesignSection: React.FC = () => {
  const [openBoxTitle, setOpenBoxTitle] = useState(dataSecurityBoxes[0].title)

  return <>
    <div className="pb-12">
      <LimitMaxWidth>
        <div className="bg-syr-snd-lt rounded-xl flex flex-col max-sm:p-4 sm:p-16">
          <div className="max-sm:text-m-s sm:text-s2 font-nun text-syr-snd uppercase">Data security</div>
          <div className="max-sm:text-m-t2 sm:text-h3 font-dm text-black mb-12">Secure by design</div>
          {dataSecurityBoxes.map(dsb => <>
            <div className={"border-solid border border-syr-snd rounded-lg max-sm:p-6 sm:p-8 mb-8 relative ".concat(openBoxTitle == dsb.title ? "bg-syr-snd-dk-18" : "bg-white")}>
              <button className="absolute right-4 top-4 bg-syr-snd max-sm:w-8 max-sm:h-8 sm:w-16 sm:h-16 rounded-md flex justify-center items-center" onClick={() => setOpenBoxTitle(dsb.title)}><CaretDownWhite/></button>
              <div className="max-sm:text-m-b sm:text-b1 font-nunb text-black">{dsb.title}</div>
                {openBoxTitle == dsb.title && dsb.text && <p className="max-sm:text-m-b sm:text-b1 font-nun text-black pr-24">{dsb.text}</p>}
                {openBoxTitle == dsb.title && dsb.bullets && 
                  <ul className="list-inside list-disc max-sm:text-m-b sm:text-b1 font-nun text-black pr-24">
                    {dsb.bullets.map(b => <>
                      <li>{b}</li>
                    </>)}
                  </ul>
                }
            </div>
          </>)}
        </div>

      </LimitMaxWidth>
    </div>
  </>
}

